import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import StructuredData from '../components/SEO/StructuredData';
import SEO from '../components/SEO/SEO';
import MainContent from 'components/MainContent/MainContent';
import { Helmet } from 'react-helmet';

const Paragraph = styled(Typography)`
  line-height: 180%;
  font-size: 1.25rem;
  margin-bottom: 2.5rem;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const List = styled('ul')`
  font-size: 1.25rem;
  list-style: none;
  padding-left: 1.75rem;
  margin-bottom: 2.5rem;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ListItem = styled('li')`
  margin-bottom: 1rem;

  &:before {
    content: '\\2022';
    color: rgba(18, 17, 39, 0.24);
    display: inline-block;
    font-weight: bold;
    width: 1.75rem;
    margin-left: -1.75rem;
  }
`;

const PressReleasePageWrapper = styled('div')`
  padding-top: 5.125rem;
  padding-bottom: 5.125rem;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-top: 3.375rem;
    padding-bottom: 3.375rem;

    h2 {
      font-size: 2rem;
    }
  }
`;

const PressContainer = styled(Container)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 49.5rem;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PressImage = styled('img')`
  max-width: 100%;
  width: 100%;
`;

const ReleaseDate = styled(Typography)`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.8px;
  line-height: 170%;
  margin-bottom: 0.25rem;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BlockQuote = styled('blockquote')`
  margin-left: 0;
  margin-right: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 140%;
  position: relative;
  padding-top: 2rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: 0;
  }

  &:before {
    content: '\\201C';
    position: absolute;
    top: 2rem;
    left: 0;
    height: 2rem;
    margin-top: -2rem;
    display: inline-block;
    color: #f25722;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 3.25rem;

    ${({ theme }) => theme.breakpoints.up('md')} {
      top: 0.2em;
      left: 0;
      width: 2rem;
      margin-top: 0;
      margin-left: -2rem;
    }
  }
`;

const PressReleasePage = () => {
  return (
    <MainContent>
      <Helmet>
        <link rel='canonical' href={`${process.env.GATSBY_SITE_URL}/press-release/`} />
      </Helmet>
      <PressReleasePageWrapper>
        <SEO
          title='Press Release'
          description='Entertainment Tech Start Up Live Bash Inc. Debuts with $21 Million Capital Raise From Oversubscribed Initial Funding Round'
          article
        />
        <StructuredData>
          {{
            '@context': 'https://schema.org',
            '@type': 'NewsArticle',
            headline:
              'Entertainment Tech Start Up Live Bash Inc. Debuts with $21 Million Capital Raise From Oversubscribed Initial Funding Round',
            image: ['https://livebash.com/logo-square-lg.png'],
            datePublished: '2022-04-19T07:30:00-06:00',
            dateModified: '2022-04-19T07:30:00-06:00',
            author: [
              {
                '@type': 'Organization',
                name: 'Live Bash',
                url: 'https://livebash.com',
                logo: 'https://livebash.com/logo-orange.png',
              },
            ],
          }}
        </StructuredData>
        <PressContainer>
          <Grid container spacing={0}>
            <Grid item xs={12} lg={12}>
              <ReleaseDate color='secondary'>Thursday, April 21, 2022</ReleaseDate>
              <Typography variant='h1' gutterBottom sx={{ fontSize: '2.5rem' }}>
                Entertainment Tech Start Up Live Bash Inc. Debuts with $21 Million Capital Raise From Oversubscribed
                Initial Funding Round
              </Typography>
              <Paragraph gutterBottom>
                <em>
                  Live Bash First to Launch High-Tech Turnkey Stages that Transform Livestream Performances into a
                  Non-Traditional Digital Asset Class Benefiting Performers, Fans, and Speculators
                </em>
              </Paragraph>
              <Paragraph>
                <strong>Chicago, IL</strong> &mdash; Live Bash Inc., an entertainment technology startup, has completed
                a seed capital raise of $21 million from successful fintech pioneers.
              </Paragraph>
              <Paragraph>
                Live Bash’s ecosystem revolves around three core components: physical stages for performers, powerful
                software using blockchain technology, and a middleware engine that supports non-traditional asset
                classes and provides liquidity.
              </Paragraph>
              <Paragraph>
                Content created on a Live Bash stage is showcased on the company’s software while its blockchain and
                smart contracts are optimized to seamlessly convert live performances into digital collectibles and
                non-fungible tokens (NFTs). Additionally, performers can easily restream to their own distribution
                channels.
              </Paragraph>
              <Paragraph>
                Live Bash is building technology to support performers and engage viewers. Viewers can purchase segments
                of performances that are minted on their blockchain with no gas fees. The digital collectibles give
                artists the opportunity to immediately realize earnings from their performances. Fans and speculators
                can buy, hold, trade, and share these segments as digital collectibles or NFTs. Live Bash has designed
                an affordable model so that any artist can rent one of their stages. Live Bash’s blockchain technology
                allows artists to livestream and mint their performances simply by pushing a button while onstage.
              </Paragraph>
              <Paragraph>
                “Imagine a spectacular stage available to anyone who wants to wow the world. We’re the technology
                company that’s behind the curtain, capitalizing on the power of blockchain to cut through the red tape
                and make this happen, securely,” said{' '}
                <a href='https://www.linkedin.com/in/chicagotrading/' target='_blank' rel='noreferrer'>
                  John Hart
                </a>
                , Live Bash CEO and technology veteran. “On a Live Bash stage, artists livestream their performances and
                their fans show their support by purchasing their favorite parts of the show instantly, which -- unlike
                videos recorded on your phone -- may increase in value over time.”
              </Paragraph>

              <Typography variant='h2' gutterBottom>
                Reserving a Live Bash Stage
              </Typography>
              <Paragraph>
                Individual artists, performers and performing groups – i.e., actors, musicians, comedians, magicians,
                interviewers, dancers, storytellers, presenters, and others - reserve time on a Live Bash stage using
                the company’s online direct-to-venue booking site, bypassing traditional gatekeepers.
              </Paragraph>
              <Paragraph>
                Be among the first to learn about venue openings, job openings, and other company milestones by emailing{' '}
                <a href='mailto:hey@livebash.com'>hey@livebash.com</a> or visit <a href='/'>www.livebash.com</a>.
              </Paragraph>
              <Paragraph>
                Live Bash will open its first venue to performers in Chicago, Illinois, later this year. Additional
                stages in Los Angeles, California, and Nashville, Tennessee, will be available for booking in early
                2023. Live Bash is currently scouting locations for its first stage outside the United States.
              </Paragraph>
              <Paragraph>
                “We are passionate about providing performers and fans alike with fairer economics, great experiences,
                and the opportunity to create non-traditional portfolios,” said Hart. “We’re designing incredible ghost
                stages around the world supported by emerging digital technology and amazing software.”
              </Paragraph>

              <Typography variant='h2' gutterBottom>
                About Live Bash
              </Typography>
              <Paragraph>
                Live Bash is a Chicago-based entertainment technology startup, founded and funded by successful fintech
                entrepreneurs, trading experts, and award-winning performers who believe booking professional stages and
                integrating new technology should be simpler. The company will offer physical, fully equipped
                performance spaces in major cities across the globe that utilize proprietary blockchain technology to
                help everyone digitize performances and build portfolios of digital assets. To learn about venue
                openings and other company milestones, email <a href='mailto:hey@livebash.com'>hey@livebash.com</a> or
                visit <a href='/'>www.livebash.com</a>.
              </Paragraph>
            </Grid>
          </Grid>
        </PressContainer>
      </PressReleasePageWrapper>
    </MainContent>
  );
};

export default PressReleasePage;
