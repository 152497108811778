import React from 'react';
import { Helmet } from 'react-helmet';

interface StructuredDataProps {
  children: object;
}

type Props = StructuredDataProps;

const StructuredData = ({ children }: Props) => {
  return (
    <Helmet>
      <script type='application/ld+json'>{JSON.stringify(children)}</script>
    </Helmet>
  );
};

export default StructuredData;
